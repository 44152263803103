@use "../../../../scss/abstracts/" as *;

.spinner__wrapper {
  // @include flex(center, center);
  display: flex;
  text-align: center;
  height: 75vh;
  align-items: center;
  justify-content: center;
}

.spinner {
  display: inline-block;
  width: 110px;
  height: 110px;
}

.spinnerSmall {
  display: inline-block;
  width: 60x;
  height: 60px;
}

.spinnerSmall:after {
  content: " ";
  display: block;
  width: 60px;
  height: 60px;
  margin: 8px;
  border-radius: 50%;
  border: 3px solid var(--primaryColor);
  border-color: var(--primaryColor) transparent var(--primaryColor) transparent;
  animation: spinner 1.2s linear infinite;
}

.spinner:after {
  content: " ";
  display: block;
  width: 110px;
  height: 110px;
  margin: 8px;
  border-radius: 50%;
  border: 10px solid var(--primaryColor);
  border-color: var(--primaryColor) transparent var(--primaryColor) transparent;
  animation: spinner 1.2s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinnerSmall {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
