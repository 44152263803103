.modalContentWrapper {
  background-color: var(--bgc);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  box-shadow: 0 4px 14px var(--shadowBgColor);
  // overflow:hidden;
  display:flex;
  flex-direction:column;
  justify-content: center;
  // align-items: center;
  gap: 2rem;
}

.closeButtonIcon {
  background-color: white;
  border-radius:5rem;
  width:20px;
  height:20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:1;
  & svg {
    color: #5688F7;
  }
}

.modalTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  color: #5688F7;
  font-size: 20px;
  background-color: #5688F7;
  padding:10px;
  width:100%''
}

.innerModalWrapper {

  padding:0 25px;
  display:flex;
  justify-content: center;
  align-items: center;
}

.modalTitle {
  color: white;
  font-size: 0.7em;
  font-weight: 400;
  text-align: center;
  text-shadow: 1px 2px 1px var(--shadowBgColor);
  letter-spacing: 1;
}

