.imageName {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fileInfo {
  display: flex;
  align-items: center;
  margin-top: 14px;
}

.imagePreview {
  display: flex;
  align-items: center;
}

.videoPreview {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnail {
  width: 50px; /* Set thumbnail size as needed */
  height: 50px;
  object-fit: cover;
  margin-right: 8px;
}

.deleteButton {
  margin-left: 8px;
}

.imageName {
  margin-left: 8px;
}
